<template>
  <div class="deleteproduct">
    <div class="delete">
      <div class="label">SKU ID</div>
      <div class="inputs">
        <textarea
          v-model.trim="inputSKUID"
          placeholder="Please Enter SKU ID"
          class="deleteinputs"
          oninput="value=value.replace(/[^\d\,]/g,'')"
          maxlength="20000"
          @input="getNumber"
        />
        <span class="reciprocal">{{ reciprocal }} / 20000</span>
        <div style="padding: 0; margin-top: 10px; margin-left: 580px">
          <el-popover v-model.trim="visible" placement="top" width="160">
            <p>SKU under all contracts will be deleted</p>
            <div style="text-align: right; margin: 0">
              <div class="reciprocalBtn">
                <el-button size="mini" type="text" @click="visible = false">
                  cancel
                </el-button>
              </div>
              <div class="reciprocalBtn">
                <el-button type="primary" size="mini" @click="Deletes">
                  confirm
                </el-button>
              </div>
            </div>
            <el-button slot="reference" type="primary" class="deletButtons">
              Delete
            </el-button>
          </el-popover>
          <!-- <el-button type="primary" @click="Deletes">Delete</el-button> -->
        </div>
      </div>
    </div>

    <div style="background: white">
      <template>
        <el-tabs v-model.trim="activeName" @tab-click="handleClick">
          <el-tab-pane label="SKU ID" name="first">
            <div class="delete">
              <div class="label">Contract No</div>
              <div class="inputs">
                <textarea
                  v-model.trim="inputContract"
                  placeholder="Please separate muitiple contractlds with commas."
                  style="height: 250px; width: 100%"
                />
              </div>
            </div>

            <div class="delete">
              <div class="label">Product Poll SKU</div>
              <div class="inputs">
                <textarea
                  v-model.trim="inputProduct"
                  spellcheck="false"
                  placeholder="SKU is separated by ',' and can be emtered at most You can fill this by 'Fill SKU Form Excel' button below"
                  style="height: 250px; width: 100%"
                />
              </div>
            </div>
            <div class="fixed">
              <el-button
                type="primary"
                size="small"
                class="buttons"
                @click="Product"
              >
                Delete
              </el-button>
            </div>
            <div style="height: 40px" />
          </el-tab-pane>
          <!-- <el-tab-pane
            label="Batch upload"
            name="second"
            style="margin-top: 10px"
          >
            <div>
              <div style="margin-left: 10px" class="UploadFile">
                <i class="el-icon-upload" />
                <p class="text">Please put the execl document here</p>
                <p class="text">* Support JPG, XLSX, EML,MSG,TXT…, file size</p>
                <el-upload
                  accept=".xlsx, .xls"
                  :action="actionUrl"
                  :data="datas"
                  :before-upload="beforeUploadFile"
                  :on-success="uploadSuccess"
                  :show-file-list="isShowFile"
                  multiple
                  :limit="limitNum"
                >
                  <div>
                    <el-button type="primary" class="sl-icon-upload">
                      Upload batch file
                    </el-button>
                  </div>
                </el-upload>
                <div style="margin-top: 10px">
                  <a
                    href="javascript:;"
                    class="el-icon-download"
                    style="
                      font-size: 14px;
                      color: rgba(34, 98, 255, 1);
                      margin-top: 8px;
                    "
                    @click="downloadFile"
                  >
                    Download Excel Template
                  </a>
                </div>
              </div>
            </div>
            <p class="UploadP">Upload History</p>
            <div class="operationLogo">
              <el-form
                ref="operationForm"
                :inline="true"
                :model="operationForm"
                label-width="90px"
              >
                <el-form-item label="Task ID" class="form-item" prop="id">
                  <el-input
                    v-model.trim="operationForm.id"
                    class="select"
                    size="mini"
                    placeholder="Please Enter"
                  />
                </el-form-item>
                <el-form-item
                  label="Task Status"
                  class="lineheight"
                  prop="taskState"
                >
                  <el-select
                    v-model.trim="operationForm.taskState"
                    placeholder="--Please Choose--"
                    size="mini"
                    class="select"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="Creator" class="form-item" prop="creator">
                  <el-input
                    v-model.trim="operationForm.creator"
                    class="select"
                    size="mini"
                    placeholder="Please Enter"
                  />
                </el-form-item>
                <el-form-item class="Search">
                  <el-button class="button" @click="onreset">Reset</el-button>
                  <el-button
                    type="primary"
                    class="button"
                    icon="el-icon-search"
                    @click="onSubmit"
                  >
                    Search
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
            <template>
              <div class="panel-body">
                <el-table
                  border
                  :data="tableData"
                  style="width: 100%"
                  class="tables"
                  :header-cell-style="{ background: '#e8f1fe' }"
                >
                  <template slot="empty" class="logo_no">
                    <img
                      :src="emptyImgSrc"
                      alt=""
                      width="118px"
                      height="110px"
                    >
                    <p style="color: #969799">No more data</p>
                  </template>
                  <el-table-column prop="id" label="Task ID" width="140" />
                  <el-table-column
                    prop="originFileUrl"
                    label="Task Name"
                    width="300"
                  />
                  <el-table-column
                    prop="created"
                    label="Task Start Time"
                    width="200"
                  />
                  <el-table-column label="Success/Total" width="140">
                    <template slot-scope="scope">
                      <span>{{ scope.row.successCount }}</span> /<span>{{
                        scope.row.totalCount
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="taskState"
                    label="Task Status"
                    width="180"
                  >
                    <template slot-scope="scope">
                      <font v-if="scope.row.taskState == 3">
                        <i class="el-icon-success" style="color: #40c740" />
                        Completed
                      </font>
                      <font v-if="scope.row.taskState == 2">
                        <i class="el-icon-error" style="color: #fa5050" />
                        Mistake
                      </font>
                      <font v-if="scope.row.taskState == 1">
                        <i class="el-icon-time" style="color: black" />
                        In progress
                      </font>
                    </template>
                  </el-table-column>
                  <el-table-column prop="creator" label="Created By" />
                  <el-table-column
                    prop="operate"
                    label="Operate"
                    width="200"
                    fixed="right"
                  >
                    <template slot-scope="scope">
                      <el-link
                        type="primary"
                        :underline="false"
                        @click="File(scope)"
                      >
                        Download File
                      </el-link>
                      <el-link
                        type="primary"
                        :underline="false"
                        @click="Results(scope)"
                      >
                        Download Results
                      </el-link>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </template>
            <div class="pageWrapper">
              <el-pagination
                :current-page="pages.currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pages.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
            </div>
            <div style="height: 30px" />
          </el-tab-pane> -->
        </el-tabs>
      </template>
    </div>
  </div>
</template>
<script>
import {
  getAddsList,
  Delete,
  removePoolAndSkuRelations,
  download
} from '@/api/VspProductApi/api';
export default {
  name: '',
  components: {
    // operationLogo
  },
  data() {
    return {
      emptyImgSrc: '',
      inputSKUID: '',
      inputContract: null,
      inputProduct: '',
      activeName: 'first',
      // 查询表单数据
      operationForm: {
        type: '',
        id: '',
        taskState: '',
        originFileUrl: '',
        timeValue: ''
      },
      options: [
        {
          value: '0',
          label: 'mistake'
        },
        {
          value: '3',
          label: 'completed'
        },
        {
          value: '1',
          label: 'In progress'
        }
      ],
      // 分页数据
      pages: {
        size: 10,
        currentPage: 1,
        total: 0
      },
      tableData: [],
      reciprocal: 0,
      visible: false,
      limitNum: 1, //选择文件个数
      datas: { creator: navigator.userAgent },
      actionUrl:
        process.env.VUE_APP_BASE_API + '/system/pool/task/batchPoolTaskDelete', //上传文件url
      isShowFile: false, //文件列表是否显示 默认不显示
      excel: 'addskuinfoTemplate.xlsx'
    };
  },
  computed: {},
  watch: {},
  created() {
    this.emptyImgSrc = '';
  },
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  methods: {
    handleClick(tab, event) {
      if (tab.name == 'second') {
        this.handleAjaxData();
      }
    },
    handleAjaxData() {
      getAddsList({ pageNo: this.pages.currentPage, pageSize: this.pages.size }).then(
        res => {
          if (res.success && res.code === 200) {
            this.tableData = res.data.dataList;
            this.pages.total = res.data.totalCount;
          } else {
            // 暂无数据
            this.tableData = [];
            this.pages.total = 0;
            this.$message({
              message: res.msg,
              type: 'error'
            });
          }
        }
      );
    },
    getNumber() {
      this.reciprocal = this.inputSKUID.length;
    },
    onreset() {
      this.onSubmit();
      this.$refs.operationForm.resetFields();
    },
    handleSizeChange(val) {
      this.pages.size = val;
      this.handleAjaxData();
    },
    handleCurrentChange(val) {
      this.pages.currentPage = val;
      this.handleAjaxData();
    },
    onSubmit() {
      this.handleAjaxData();
    },
    Deletes() {
      if(this.inputSKUID===null || !this.inputSKUID.length){
        return this.$message({
          message:'Please fill in SKU ID'
        })
      }
      this.visible = false;
      const arr = [];
      const array = this.inputSKUID.split(',');
      if(array.length>20000){
        return this.$message({
          message:'Up to 20000 SKUs'
        })
      }
      array.forEach(items => {
        arr.push(items);
      });
      Delete({ skuIds: arr }).then(res => {
        this.inputSKUID = null;
        if (res.code == 200) {
          this.$message({
            message: 'Operation succeeded',
            type: 'success'
          });
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    Product() {
      const arr = [];
      var number = null;
      var vsp = null;
      // inputProduct
      if(!this.inputProduct==null || this.inputContract==null){
        return this.$message({
          message:'Please fill in Contract No or Product Poll SKU'
        })
      }
      if (this.inputProduct != null) {
        number = this.inputProduct.split(',');
        number = number.map(function(item, index, array) {
          return item - 0;
        });
      }
      if (this.inputContract != null) {
        vsp = this.inputContract.split(',');
      }

      removePoolAndSkuRelations({
        skuIds: number,
        contractNos: vsp
      }).then(res => {
        if (res.code == 200) {
          this.$message({
            message: 'Operation succeeded',
            type: 'success'
          });
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    downloadFile() {
      download(this.excel).then(res => {
        //console.log(res);
        var downloadUrl = window.URL.createObjectURL(res);
        var anchor = document.createElement('a');
        anchor.href = downloadUrl;
        anchor.download = ' Excel Template.xlsx';
        anchor.click();
        window.URL.revokeObjectURL(res);
      });
    },
    //文件上传之前的钩子
    beforeUploadFile(file) {
      var FileExt = file.name.replace(/.+\./, '');
      if (['xls', 'xlsx'].indexOf(FileExt.toLowerCase()) === -1) {
        this.$message({
          type: 'warning',
          message: 'Please upload attachments with suffix XLS and xlsx！'
        });
        return false;
      }
      //file.size 以字节为单位
      this.isLt2k = file.size / 2048 < 100 ? '1' : '0';
      if (this.isLt2k === '0') {
        this.$message({
          message: 'Upload file size cannot exceed 100k!!',
          type: 'error'
        });
      }
      return this.isLt2k === '1' ? true : false;
    },
    //文件上传成功时的钩子
    uploadSuccess(response, file, fileList) {
      this.$emit('addlists', 'limitNum');
      //console.log('上传文件', response.data);
      //console.log('返回状态', response.code);
      //console.log(fileList);
      if (response.code != '200') {
        return this.$message.error('Import error');
      } else {
        this.isShowFile = false;
        return this.$message.success('Import success');
      }
    },
    File(row) {
      download(row.row.originFileUrl).then(res => {
        var downloadUrl = window.URL.createObjectURL(res);
        var anchor = document.createElement('a');
        anchor.href = downloadUrl;
        anchor.download = row.row.originFileUrl;
        anchor.click();
        window.URL.revokeObjectURL(res);
      });
    },
    Results(row) {
      download(row.row.resultFileUrl)
        .then(res => {
          var downloadUrl = window.URL.createObjectURL(res);
          var anchor = document.createElement('a');
          anchor.href = downloadUrl;
          anchor.download = row.row.originFileUrl;
          anchor.click();
          window.URL.revokeObjectURL(res);
        })
        .catch(error => {
          this.$message({
            type: 'error',
            message: 'There is no source file yet'
          });
        });
      //console.log(2);
    }
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
::v-deep .el-tabs__nav {
  margin-left: 40px;
}
.label {
  font-family: HelveticaNeue;
  font-size: 12px;
  font-weight: 500;
}
.lineheight {
  margin-left: 16px !important;
  ::v-deep .el-form-item__label {
    line-height: 18px;
  }
  /deep/ .el-input__inner {
    width: 200px !important;
  }
}
.deleteproduct {
  .inputs {
    width: 90%;
    background: white;
    .deleteinputs {
      height: 250px;
      width: 100%;
      .reciprocal {
        .reciprocalBtn {
          display: inline-block;
        }
      }
    }
  }
  .delete {
    display: flex;
    background: #fff;
    margin-bottom: 20px;
    padding: 10px 0;
    div:nth-of-type(1) {
      width: 13%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 15px;
      font-weight: bold;
    }
  }
  .Action {
    /deep/ .el-form-item__label {
      line-height: 18px !important;
    }
  }
  .fixed {
    bottom: 0px;
    width: 100%;
    height: 80px;
    background: rgba(255, 255, 255, 1);
    border-radius: 0 0 8px 8px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.08);
    z-index: 999;
  }
  .buttons {
    width: 240px;
    height: 40px;
    //background: rgba(34, 98, 255, 1);
    border-radius: 4px;
    font-size: 16px;
    font-family: ArialMT;
    margin: 20px 50%;
    transform: translateX(-50%);
  }
  .UploadP {
    margin-left: 12px;
    font-size: 16px;
    font-weight: Bold;
  }
  .button {
    width: 100px !important;
    height: 32px !important;
    padding: 0 12px;
  }
  .deletButtons {
    width: 240px;
    height: 40px;
    border-radius: 4px;
    font-size: 16px;
    font-family: ArialMT;
    transform: translateX(-50%);
  }
  .UploadFile {
    width: 1152px;
    height: 260px;
    background: rgba(183, 188, 201, 0.1);
    border: 1px solid rgba(36, 37, 38, 0.35);
    border-radius: 4px;
    margin: 0 auto !important;
    text-align: center;
  }
  /deep/ .text:nth-of-type(1) {
    font-size: 14px !important;
  }
  /deep/ .text:nth-of-type(2) {
    color: rgba(183, 188, 201, 0.5);
    border-radius: 4px;
    font-size: 14px !important;
  }
  /deep/ .el-icon-upload {
    font-size: 60px;
    color: rgb(128, 128, 128);
    margin-top: 0px;
  }
  .Search {
    margin-left: 50px;
  }
  .panel-body {
    padding: 0 16px;
  }
}
/deep/.el-button {
  // padding: 0 12px !important;
  //width: 100px;
  font-family: ArialMT;
  font-size: 14px;
}

::v-deep .el-button--primary {
  color: #fff;
  background-color: #2262ff;
  border-color: #2262ff;
}
::v-deep .el-button--text {
  color: #2262ff;
}
::v-deep .el-link--primary {
  color: #2262ff;
}
::v-deep .el-table {
  .cell {
    white-space: pre-wrap;
    word-break: keep-all;
  }
}

::v-deep .el-pager {
  li {
    border: 0;
  }
}
.pageWrapper {
  float: right;
  height: 50px;
  padding-top: 10px;
  margin-right: 16px;
}
</style>
